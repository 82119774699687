
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, watchEffect } from 'vue';
import { Input, Select } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { GET_ATTRIBUTE_VALUES } from '../graphql/Queries';
import { CREATE_ATTRIBUTE_VALUE } from '../graphql/Mutations';
import { AddAttributeValueForm } from '../interfaces';
import { GET_ATTRIBUTES } from '../../attributes/graphql/Queries';
import { useI18n } from 'vue-i18n';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
import InnerLoader from '../../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'add new attribute value',
    components: {
        Input,
        Select,
        InnerLoader
    },

    setup() {
        const modal: any = ref();
        const i18n = useI18n();
        const loading = ref(false);
        const locales = ref([]) as Record<any, any>;
        const locale = ref('en');
        const attributes = ref([]) as Record<any, any>;
        const emitter: any = inject('emitter');
        const variant_types = ref({});
        const addAttributeValueForm = ref<null | HTMLFormElement>(null);

        const attributeValuesList = ref([]);
        // Get Attributes list
        const get_attribute = () => {
            Apollo.watchQuery({
                query: GET_ATTRIBUTES,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only'
            }).subscribe(({ data }) => {
                attributeValuesList.value = [];
                attributeValuesList.value = data?.attributes?.data;
            });
        };

        // Store Name in Danish and English
        const jsonNameFields = ref({}) as Record<any, any>;

        const loadLang = () => {
            const locales_data = Apollo.readQuery({
                query: GET_LOCALES
            });
            locales_data.locales.forEach(element => {
                locales.value.push({
                    label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
                    value: element.locale
                });

                jsonNameFields.value[element.locale] = { name: '' };
            });
        };

        loadLang();

        variant_types.value = [
            {
                label: 'message.COLOR',
                value: 'Color'
            },
            {
                label: 'message.SIZE',
                value: 'Size'
            },
            {
                label: 'message.WEIGHT',
                value: 'Weight'
            }
        ];

        watchEffect(() => {
            attributes.value = [];
            attributeValuesList.value.forEach((element: Record<any, any>) => {
                let defaultLocale = '' as any;
                if (JSON.parse(element.name)[`${locale.value}`] == undefined) {
                    defaultLocale = 'en';
                } else {
                    defaultLocale = locale.value;
                }
                attributes.value.push({
                    value: element.id,
                    label: JSON.parse(element.name)[`${defaultLocale}`].name
                });
            });
        });

        // Given Add Attribute Value Form Interface
        const attributeValueData = ref<AddAttributeValueForm>({
            name: '',
            attribute_id: null,
            variant_type: '',
            variant_value: ''
        });

        // On change input value
        const changeLang = (e: string) => {
            attributeValueData.value.name = jsonNameFields.value[e].name;
        };

        // update on change value input
        const updateValue = (e: string) => {
            jsonNameFields.value[locale.value].name = e;
        };

        // Submit Handler Request
        const submitHandlerForm = async () => {
            addAttributeValueForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    for (const i in jsonNameFields.value) {
                        if (jsonNameFields.value[i].name == '') {
                            jsonNameFields.value[i].name = attributeValueData.value.name;
                        }
                    }
                    const formData = {
                        name: JSON.stringify(jsonNameFields.value),
                        attribute_id: attributeValueData.value.attribute_id,
                        variant_type: attributeValueData.value.variant_type,
                        variant_value: attributeValueData.value.variant_value
                    };

                    loading.value = true;
                    await Apollo.mutate({
                        mutation: CREATE_ATTRIBUTE_VALUE,
                        variables: { input: formData },
                        update: (store, { data: { create_attribute_value } }) => {
                            const pervious_record = store.readQuery({
                                query: GET_ATTRIBUTE_VALUES,
                                variables: {
                                    page: 1,
                                    limit: 10
                                }
                            }) as Record<any, any>;
                            store.writeQuery({
                                query: GET_ATTRIBUTE_VALUES,
                                variables: {
                                    page: 1,
                                    limit: 10
                                },
                                data: {
                                    attribute_values: {
                                        ...pervious_record.attribute_values,
                                        data: [create_attribute_value, ...pervious_record.attribute_values.data]
                                    }
                                }
                            });
                            modal.value.hide();
                            loading.value = false;
                            Notify.success(`${i18n.t('message.RECORD_ADDED_SUCCESSFULLY')}`);
                        }
                    }).catch(() => {
                        loading.value = false;
                    });
                }
            });
        };

        const changeType = () => {
            attributeValueData.value.variant_value = '';
        };

        // Reset Form Data On Model Popup
        const resetForm = () => {
            get_attribute();
            locale.value = 'en';
            emitter.emit('clearInput');
            locale.value = 'en';
            attributeValueData.value.variant_value = '';
            addAttributeValueForm.value?.resetFields();
            for (const i of Object.getOwnPropertyNames(jsonNameFields.value)) {
                jsonNameFields.value[i].name = '';
            }
        };

        // Emitter To Open Model
        emitter.on('addAttributeValueAction', () => {
            resetForm();
            modal.value = new Modal(document.getElementById('modal_add_attribute_value')) as HTMLElement;
            modal.value.show();
        });

        return {
            loading,
            locales,
            locale,
            variant_types,
            attributes,
            attributeValueData,
            addAttributeValueForm,
            submitHandlerForm,
            changeType,
            changeLang,
            updateValue
        };
    }
});
